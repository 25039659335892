.footers {
  background: #a9a392;
  position: absolute;
  width: 100%;
  height: 60px;
}

.iconos {
  align-items: flex-end;
}
