/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .card {
            position: relative;
            width: 300px;
            height: 200px;
            background-color: #a8a29e;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            perspective: 1000px;
            box-shadow: 0 0 0 5px #7f7a78;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            margin: auto;
        }
    
        .card svg {
            width: 48px;
            fill: #333;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    
        .card:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
        }
    
        .card__content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;
            background-color: #a8a29e;
            transform: rotateX(-90deg);
            transform-origin: bottom;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    
        .card:hover .card__content {
            transform: rotateX(0deg);
        }
    
        .card__title {
            margin: 0;
            font-size: 24px;
            color: #333;
            font-weight: 700;
        }
    
        .card:hover svg {
            scale: 0;
        }
    
        .card__description {
            margin: 10px 0 0;
            font-size: 14px;
            color: #000000;
            line-height: 1.4;
        }
    
        .imagencontenido1 {
            height: 200px;
            width: 300px;
        }
    
        .imagencontenido2 {
            height: 200px;
            width: 300px;
        }
    
        .imagencontenido3 {
            height: 200px;
        }
    
        .imagencontenido4 {
            height: 200px;
            width: 300px;
        }
    
        .tituloContenido {
            color: #6c5b2f;
        }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .card {
            position: relative;
            width: 300px;
            height: 200px;
            background-color: #a8a29e;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            perspective: 1000px;
            box-shadow: 0 0 0 5px #7f7a78;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            margin: auto;
        }
    
        .card svg {
            width: 48px;
            fill: #333;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    
        .card:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
        }
    
        .card__content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;
            background-color: #a8a29e;
            transform: rotateX(-90deg);
            transform-origin: bottom;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    
        .card:hover .card__content {
            transform: rotateX(0deg);
        }
    
        .card__title {
            margin: 0;
            font-size: 24px;
            color: #333;
            font-weight: 700;
        }
    
        .card:hover svg {
            scale: 0;
        }
    
        .card__description {
            margin: 10px 0 0;
            font-size: 14px;
            color: #000000;
            line-height: 1.4;
        }
    
        .imagencontenido1 {
            height: 200px;
            width: 300px;
        }
    
        .imagencontenido2 {
            height: 200px;
            width: 300px;
        }
    
        .imagencontenido3 {
            height: 200px;
        }
    
        .imagencontenido4 {
            height: 200px;
            width: 300px;
        }
    
        .tituloContenido {
            color: #6c5b2f;
        }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 700px) {
    .card {
            position: relative;
            width: 300px;
            height: 200px;
            background-color: #a8a29e;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            perspective: 1000px;
            box-shadow: 0 0 0 5px #7f7a78;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            margin: auto;
        }
    
        .card svg {
            width: 48px;
            fill: #333;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    
        .card:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
        }
    
        .card__content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;
            background-color: #a8a29e;
            transform: rotateX(-90deg);
            transform-origin: bottom;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    
        .card:hover .card__content {
            transform: rotateX(0deg);
        }
    
        .card__title {
            margin: 0;
            font-size: 24px;
            color: #333;
            font-weight: 700;
        }
    
        .card:hover svg {
            scale: 0;
        }
    
        .card__description {
            margin: 10px 0 0;
            font-size: 14px;
            color: #000000;
            line-height: 1.4;
        }
    
        .imagencontenido1 {
            height: 200px;
            width: 300px;
        }
    
        .imagencontenido2 {
            height: 200px;
            width: 300px;
        }
    
        .imagencontenido3 {
            height: 200px;
        }
    
        .imagencontenido4 {
            height: 200px;
            width: 300px;
        }
    
        .tituloContenido {
            color: #6c5b2f;
        }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 900px) {
    .info {
            display: none;
        }
    .card {
            position: relative;
            width: 350px;
            height: 200px;
            background-color: #a8a29e;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            perspective: 1000px;
            box-shadow: 0 0 0 5px #7f7a78;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            margin: auto;
        }
    
        .card svg {
            width: 48px;
            fill: #333;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    
        .card:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
        }
    
        .card__content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;
            background-color: #a8a29e;
            transform: rotateX(-90deg);
            transform-origin: bottom;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    
        .card:hover .card__content {
            transform: rotateX(0deg);
        }
    
        .card__title {
            margin: 0;
            font-size: 24px;
            color: #333;
            font-weight: 700;
        }
    
        .card:hover svg {
            scale: 0;
        }
    
        .card__description {
            margin: 10px 0 0;
            font-size: 14px;
            color: #000000;
            line-height: 1.4;
        }
    
        .imagencontenido1 {
            height: 200px;
            width: 350px;
        }
    
        .imagencontenido2 {
            height: 200px;
            width: 350px;
        }
    
        .imagencontenido3 {
            height: 200px;
        }
    
        .imagencontenido4 {
            height: 200px;
            width: 350px;
        }
    
        .tituloContenido {
            color: #6c5b2f;
        }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .info{
        display: none;
    }
    .card {
            position: relative;
            width: 400px;
            height: 200px;
            background-color: #a8a29e;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            perspective: 1000px;
            box-shadow: 0 0 0 5px #7f7a78;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            margin: auto;
            padding: 30px;
        }
    
        .card svg {
            width: 48px;
            fill: #333;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    
        .card:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
        }
    
        .card__content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;
            background-color: #a8a29e;
            transform: rotateX(-90deg);
            transform-origin: bottom;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    
        .card:hover .card__content {
            transform: rotateX(0deg);
        }
    
        .card__title {
            margin: 0;
            font-size: 24px;
            color: #333;
            font-weight: 700;
        }
    
        .card:hover svg {
            scale: 0;
        }
    
        .card__description {
            margin: 10px 0 0;
            font-size: 14px;
            color: #000000;
            line-height: 1.4;
        }
    
        .imagencontenido1 {
            height: 200px;
            width: 400px;
        }
    
        .imagencontenido2 {
            height: 200px;
            width: 400px;
        }
    
        .imagencontenido3 {
            height: 200px;
        }
    
        .imagencontenido4 {
            height: 200px;
            width: 400px;
        }
    
        .tituloContenido {
            color: #6c5b2f;
        }
}

