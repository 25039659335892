
/* Extra small devices (phones, 600px and down)*/
@media only screen and (max-width: 600px) {
  .imagenCarousel1 {
      height: 300px;
      width: 100%;
    }
  
    .imagenCarousel2 {
      height: 300px;
      width: 100%;
    }
  
    .imagenCarousel3 {
      height: 300px;
      width: 100%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .imagenCarousel1 {
      height: 400px;
      width: 100%;
    }
  
    .imagenCarousel2 {
      height: 400px;
      width: 100%;
    }
  
    .imagenCarousel3 {
      height: 400px;
      width: 100%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) {
.imagenCarousel1 {
    height: 400px;
    width: 100%;
  }

  .imagenCarousel2 {
    height: 400px;
    width: 100%;
  }

  .imagenCarousel3 {
    height: 400px;
    width: 100%;
  }

}

/*Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .imagenCarousel1 {
      height: 400px;
      width: 100%;
    }
  
    .imagenCarousel2 {
      height: 400px;
      width: 100%;
    }
  
    .imagenCarousel3 {
      height: 400px;
      width: 100%;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1440px) {
  .Carousel {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 600px;
      height: 400px;
    }
  
    .espacio {
      padding-top: 81px;
    }
  
  
    .slide {
      border-radius: 0.5rem;
      box-shadow: 0px 0px 7px #666;
      /*width: 100%;
      height: 10%;*/
    }
  
    .slide-hidden {
      display: none;
    }
  
    .arrow {
      position: absolute;
      width: 2rem;
      height: 2rem;
      color: white;
      filter: drop-shadow(0px 0px 5px #555);
    }
  
    .arrow:hover {
      cursor: pointer;
    }
  
    .arrow-left {
      left: 1rem;
    }
  
    .arrow-right {
      right: 1rem;
    }
  
    .indicators {
      display: flex;
      position: absolute;
      bottom: 1rem;
    }
  
    .indicator {
      background-color: white;
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 100%;
      border: none;
      outline: none;
      box-shadow: 0px 0px 5px #555;
      margin: 0 0.2rem;
      cursor: pointer;
    }
  
    .indicator-inactive {
      background-color: grey;
    }
  
    .img {
      height: 100%;
      width: 100%;
    }
  
    .imagenCarousel1 {
      height: 470px;
      width: 100%;
    }
  
    .imagenCarousel2 {
      height: 470px;
      width: 100%;
    }
  
    .imagenCarousel3 {
      height: 470px;
      width: 100%;
    }
}