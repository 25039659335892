.container{
	width:100%;
	margin:10px auto;
}
.portfolio-menu {
    text-align: center;
}

.portfolio-menu ul li {
    display: inline-block;
    margin: 0;
    list-style: none;
    padding: 10px 15px;
    cursor: pointer;
    -webkit-transition: all 05s ease;
    -moz-transition: all 05s ease;
    -ms-transition: all 05s ease;
    -o-transition: all 05s ease;
    transition: all .5s ease;
}

.portfolio-item {
    width:100%;
}

.portfolio-item .item {
    /*width:303px;*/
    float: left;
    margin-bottom: 10px;
}

.titulo {
    border-bottom: 3px solid;
}

.tituloGaleria {
    color: #6c5b2f;
    border-bottom: 3px solid;   
}